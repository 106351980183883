import fetch from "isomorphic-unfetch";

const $table = $('.js-quota')
const $resultFields = $('.js-result')

const getAllData = () => {
    const header = $('.js-quota thead tr th').toArray().map((item, i) => {
        return $(item).text();
    });

    const rows = $('.js-quota tbody tr:not(".js-quota-total")').toArray().map((item, i) => {
        const tds = $(item).children().toArray();
        const cells = [
            $(tds[0]).text(),
            $(tds[1]).text(),
            $(tds[2]).text(),
            $(tds[3]).find('input').val(),
            $(tds[4]).find('input').val(),
        ];
        return cells;
    });
    const total = $(".js-quota-total td").toArray().map((item, i) => $(item).text());
    rows.push(total);

    return {
        'header': header,
        'rows': rows,
        'type': 'quota',
        'period': $(".js-quota").data('form-period')
    }
}

async function getBlob(url, data) {
    const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(res => res.blob())
    ;
    return response;
}

const getData = () => ($('[data-form-period-instance-id]').toArray().map((row, i) => {
    const dataItem = {}
    const $row = $(row)

    dataItem.form_period_instance = $row.data('form-period-instance-id')

    dataItem.quotas = $row.find('[data-quota-type]').toArray().map((cell, j) => {
        const quotaObj = {}
        const $quota = $(cell)
        const val = $quota.find('input[type=text]').val()

        quotaObj.id = $quota.data('quota-id')
        quotaObj.quota_type = $quota.data('quota-type')
        quotaObj.quota_value = val

        return quotaObj
    })

    return dataItem
}))

$table.find('input[type=text]').on('keyup', function () {
    const sum = {}
    $('[data-form-period-instance-id]').each(function (i) {
        $(this).find('[data-quota-input]').each(function (j) {
            const $quota = $(this)
            const val = $quota.find('input[type=text]').val()
            if (!(`sum${j}` in sum)) {
                sum[`sum${j}`] = 0
            }
            sum[`sum${j}`] += +val
        })
    })
    $resultFields.each(function (i) {
        $(this).text(sum[`sum${i}`])
    })
})

$('#quotes-save-btn').on('click', function () {

    $.post($(this).data('url'), JSON.stringify(getData()))
        .done(function () {
            location.reload();
        })
        .fail(function() {
            alert( "error" );
        })
});

$('#quota-xls-btn').on('click', async function () {
    const url = $(this).data('url');
    const response = await getBlob(url, getAllData());
    const blobUrl = URL.createObjectURL(response);
    const $a = $("<a>");
    $a.attr("href", blobUrl);
    $("body").append($a);
    $a.attr("download", "отчёт по квотам.xlsx");
    $a[0].click();
    $a.remove();
});

/*
const data = [
    {
        'form_period_instance': 1,
        'quotas': [
            {
                'quota_type': 11,
                'quota_value': 500
            },
            {
                'quota_type': 11,
                'quota_value': 500
            }
        ]
    },
    {
        'form_period_instance': 2,
        'quotas': [
            {
                'quota_type': 11,
                'quota_value': 500
            },
            {
                'quota_type': 11,
                'quota_value': 500
            }
        ]
    }
]
*/
