const orgs = $('.js-organizations')
const formPeriod = orgs.data('form-period')

$('.js-organizations').on('click', '.js-organization', function () {
    const $this = $(this)
    const container = $this.parent().parent()

    const orgId = $this.data('organization')
    const slider = $('<div class="form-group-item__slider js-slide-down overflow-hidden"/>')
    const loading = container.find('.js-loading')

    if (!$this.hasClass('clicked')) {
        $this.addClass('clicked')
        $this.contents().get(0).nodeValue = 'свернуть'
        loading.fadeIn(200)
        $.get(`/api/organization/${orgId}/${formPeriod}`)
            .done(function (data) {
                slider.append(data)
                container.append(slider)
                slider.slideDown(300)
                loading.fadeOut(200)
            })
            .fail(function () {
                alert("Ошибка загрузки");
            })
    } else {
        $this.removeClass('clicked')
        $this.contents().get(0).nodeValue = 'развернуть'
        container.find('.js-slide-down').slideUp(300).promise().done(function(){$(this).remove()})
    }
})