$('.js-custom-upload').off('change').on('change', function (e) {
    uploadFile(e)
})

const uploadFile = (e) => {
    let namesObj = e.target.files
    let block = $(e.target).closest('.upload-custom')

    $('.upload-custom__files-list').remove()

    let fileNameContainer = $('<div class="upload-custom__files-list"></div>')

    block.append(fileNameContainer)

    let names = Object.keys(namesObj).map(e => namesObj[e].name)
    let nameBlocks = names.map(el => `<div class="upload-custom__file">${el}</div>`).join('')

    fileNameContainer.html(nameBlocks)
}