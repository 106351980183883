import 'select2/dist/js/select2.full'

$('.js-select2-type-1').select2({
    containerCssClass: "select2-type-1",
    dropdownCssClass: "select2-type-1-dropdown",
    minimumResultsForSearch: 20,
    language: {
        noResults: ()=>'Не найдено'
    }
});
