import fetch from 'isomorphic-unfetch';

const $table = $('.js-subsidy')
const $resultFields = $('.js-result')

const getData = () => {
    const header = $('.js-subsidy thead tr th').toArray().map((item, i) => {
        return $(item).text();
    });

    const rows = $('.js-subsidy tbody tr:not(".js-subsidy-total")').toArray().map((item, i) => {
        const tds = $(item).children().toArray();
        const cells = tds.reduce((cells, td) => {
            if ($(td).find('input').length > 0) {
                return [...cells, $(td).find('input').val()];
            } else {
                return [...cells, $(td).text()]
            }
        }, []);
        return cells;
    });
    const total = $(".js-subsidy-total td").toArray().map((item, i) => $(item).text());
    rows.push(total);

    return {
        'header': header,
        'rows': rows,
        'type': 'subsidy',
        'period': $(".js-subsidy").data('form-period')
    }
}

$table.find('input[type=text]').on('keyup', function () {
    const sum = {}
    $('[data-form-period-instance-id]').each(function (i) {
        $(this).find('[data-subsidy-type]').each(function (j) {
            const $subsidy = $(this)
            const val = $subsidy.find('input[type=text]').val()
            if (!(`sum${j}` in sum)) {
                sum[`sum${j}`] = 0
            }
            sum[`sum${j}`] += +val
        })
    })
    $resultFields.each(function (i) {
        $(this).text(sum[`sum${i}`])
    })
})

$('.js-calculate-subsidies').on('click', function () {
    const monthCoefficient = $('#month-coefficient').val();
    window.location.href = `${$(this).data('url')}?month_coefficient=${monthCoefficient}`;
})

async function getBlob(url, data) {
    const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(res => res.blob())
    ;
    return response;
}

$('#subsidy-xls-btn').on('click', async function () {
    const url = $(this).data('url');
    const response = await getBlob(url, getData());
    const blobUrl = URL.createObjectURL(response);
    const $a = $("<a>");
    $a.attr("href", blobUrl);
    $("body").append($a);
    $a.attr("download", "отчёт по субсидиям.xlsx");
    $a[0].click();
    $a.remove();
});